<template>
  <div class="main-container mt-sm-5 mt-3">
    <div class="container" ref="formBox">
      <div class=" content-box">
        <div class="col-xl-12 box-title border-bottom mb-3">
          <h3 class="font-weight-bold my-3">{{$t('forgot_password_reset')}}</h3>
        </div>
        <div class="container" v-if="validationErrors.length > 0">
          <p v-for="(error, index) in validationErrors" :key="index" class="alert alert-danger p-2">
            {{error}}
          </p>
        </div>
        <div v-if="confirmationMessage">
          <p class="alert alert-success p-2">
            {{successMessage}}
          </p>
        </div>
        <b-form @submit.prevent="resetPassword" v-if="show" class="pt-2 col-12 col-lg-6 m-auto">
          <p>{{$t('forgotten_password_support_form')}}</p>
          <b-form-group
            id="input-group-3" label-for="input-3"
            :description="$t('password_description')">
            <b-form-input
              id="input-3"
              v-model="form.password.first"
              type="password"
              required
              @input="applyPasswordStrength(form.password.first)"
              :placeholder="$t('choose_password')"
            ></b-form-input>
            <meter min="0" max="4" id="password-strength-meter" class="w-100 mt-2" ref="passwordStrengthMeter"></meter>
            <p id="password-strength-text" class="mb-0" ref="passwordStrengthText"></p>
          </b-form-group>
          <b-form-group id="input-group-4" label-for="input-4">
            <b-form-input
              id="input-4"
              v-model="form.password.second"
              type="password"
              required
              :placeholder="$t('confirm_password')"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-3">
            <div v-if="showSpinner">
              <b-button type="submit" class="w-100 py-2" variant="primary" disabled="disabled">
                <b-spinner class="" small label="Large Spinner"></b-spinner>
              </b-button>
            </div>
            <div v-else>
              <b-button type="submit" class="w-100 py-2" variant="primary" :disabled="confirmationMessage">
                {{ $t('submit') }}
              </b-button>
            </div>
          </b-form-group>
        </b-form>
        <b-button v-on:click="toggleModal"  class="col-12 col-lg-6 mb-4" variant="primary" v-if="requestNewToken">
          {{ $t('request_new_token') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import apiService from '../../apiService.js'
export default {
  name: 'forgottenPassword',
  data () {
    return {
      form: {
        token: '',
        password: {
          first: '',
          second: ''
        }
      },
      result: 0,
      show: true,
      showSpinner: false,
      strength: {
        0: this.$t('password-strength-worst'),
        1: this.$t('password-strength-bad'),
        2: this.$t('password-strength-weak'),
        3: this.$t('password-strength-good'),
        4: this.$t('password-strength-strong')
      },
      showSpinnerEtn: false,
      validationErrors: [],
      confirmationMessage: false,
      requestNewToken: false
    }
  },
  methods: {
    ...mapActions([
      'doLoginAfterResetPassword'
    ]),
    resetPassword (e) {
      e.preventDefault()
      this.showSpinner = true
      if (this.validateForm()) {
        this.form.token = this.$route.params.token
        apiService.resetPassword(this.form).then((response) => {
          this.confirmationMessage = true
          this.showSpinner = false
          this.successMessage = response.data
          this.doLoginAfterResetPassword({ response: response })
        }).catch((error) => {
          this.validationErrors = [error.response.data.error_message]
          this.showSpinner = false
          if (error.response.data.error_code === 'HTTP-400') {
            this.show = false
            this.requestNewToken = true
          }
        })
      } else {
        this.showSpinner = false
      }
    },
    toggleModal () {
      this.$bvModal.show('forgotPassword')
    },
    checkPasswordStrength (password) {
      let score = this.scorePassword(password)
      let idx = 0
      if (score > 90) {
        idx = 4
      } else if (score > 70) {
        idx = 3
      } else if (score >= 40) {
        idx = 2
      } else if (score >= 20) {
        idx = 1
      }
      return idx
    },
    scorePassword (password) {
      let score = 0

      if (!password) { return score }

      let letters = {}
      for (let i = 0; i < password.length; i++) {
        letters[password[i]] = (letters[password[i]] || 0) + 1
        score += 5.0 / letters[password[i]]
      }

      let variations = {
        digits: /\d/.test(password),
        lower: /[a-z]/.test(password),
        upper: /[A-Z]/.test(password),
        nonWords: /\W/.test(password)
      }

      let variationCount = 0
      for (let check in variations) {
        variationCount += (variations[check]) ? 1 : 0
      }
      score += (variationCount - 1) * 10
      return Math.trunc(score)
    },
    applyPasswordStrength (password) {
      this.result = this.checkPasswordStrength(password)
      let meter = this.$refs.passwordStrengthMeter
      let text = this.$refs.passwordStrengthText
      meter.value = this.result

      if (password !== '') {
        text.innerHTML = this.$t('password-strength') + ': ' + '<strong>' + this.strength[this.result] + '</strong>' + "<span class='feedback'></span>"
      } else {
        text.innerText = ''
      }
    },
    validateForm () {
      this.validationErrors = []
      if (this.form.password.first.length === 0 || this.form.password.first !== this.form.password.second) {
        this.validationErrors.push(this.$t('join_validator_password_confirm'))
      }
      if (this.form.password.first.length > 0 && this.checkPasswordStrength(this.form.password.first) < 2) {
        this.validationErrors.push(this.$t('join_validator_password_weak'))
      }
      return this.validationErrors.length === 0
    }
  }
}
</script>

<style scoped>

</style>
